/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "@styles/dock.scss";
import TerminalIcon from "@static/terminal.png";
import ContactIcon from "@static/contact.png";
import FinderIcon from "@static/finder.png";
import CodeIcon from "@static/code.png";
import GithubIcon from "@static/lapalmera.png";
import FolderIcon from "@static/folder.png";
import NothingIcon from "@static/NothingIcon.png";
import WiseIcon from "@static/riverside.png";
import { Link } from "react-router-dom";
// import { useDockStore } from "@contexts/Dock/Dock";
import DevopsIcon from "@static/seaside.png";
import mIcon from "@static/netsyntax.png";

const DockContent = () => {
	return (
		<div className="main-contain">
			<div className="container">
				<div className="dock">
					<span></span>
					<div className="dock-nav">
						<ul>
							<Link to="/">
								<li
									data-title="Home"
									className="full-width-icon"
								>
									<img
										src={TerminalIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>
							<Link to="/vscode">
								<li data-title="VS Code">
									<img
										src={CodeIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>
							<a href="https://www.lapalmerahotel.com/" target="_blank">
								<li data-title="La Palmera Hotel">
									<img
										src={GithubIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</a>
							<a href="https://netsyntax.es/" target="_blank">
								<li data-title="Netsyntax">
									<img
										src
										={mIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</a>
							<a href="https://riverside-cruises.com/" target="_blank">
								<li data-title="Riverside Luxury Cruises">
									<img
										src
										={WiseIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</a>
							<a href="https://www.seaside-collection.com/es/" target="_blank">
								<li data-title="Seaside Collection">
									<img
										src
										={DevopsIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</a>
							<div className="separator" />
							<a>
							<Link to="/resume">
								<li data-title="Live Attack Map">
									<img
										src={FolderIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>
							</a>
							<a>
							<Link to="/contact">
							<li data-title="Contact">
									<img
										src={ContactIcon}
										className="img-fluid"
										alt="mac"
									/>
								</li>
							</Link>
							</a>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DockContent;